/* eslint-disable react/display-name */
import React, { ComponentType } from 'react';
import styles from './styles.module.scss';
import { useIntersectionObserver } from '@starsoft/common/hooks';

export const withLazyLoad = <T extends object>(
  WrappedComponent: ComponentType<T>,
  threshold = 0,
): React.FC<T> => {
  return (props: T) => {
    const [isInView, ref, bufferRef] = useIntersectionObserver(threshold);

    return (
      <div className={styles.container}>
        <div className={styles.container__buffer} ref={bufferRef} />
        <div ref={ref} className={styles.container__content}>
          {isInView ? <WrappedComponent {...props} /> : null}
        </div>
      </div>
    );
  };
};
